<template>
  <form-layout
    @submit="update"
    title="Notifications"
    icon="circle_notifications"
    :is-loading="state.isInitialLoading"
  >
    <errors :errors="state.validationErrors" />

    <div class="flex flex-col space-y-6">
      <div v-for="(alert, index) in alerts" :key="index" class="relative border border-big-stone border-opacity-20 rounded-md p-3">

        <div class="absolute top-0 left-1 px-1 bg-white text-sm text-blumine font-semibold leading-none transform -translate-y-1/2">{{ alert.name }}</div>

        <div class="flex items-center flex-wrap">
          <template v-for="(part, index) in alert.parts" :key="index">
            <input v-if="part.type === 'input'" v-model.number="state.form[alert.id][part.value]" type="number" step="1" min="0" class="w-16 p-1 text-center border-t-0 border-l-0 border-r-0 border-b-2 border-big-stone focus:border-big-stone focus:ring-big-stone font-bold mr-1">
            <span v-else class="text-big-stone mr-1">{{ part.value }}</span>
          </template>
        </div>
      </div>
    </div>

    <template #actions>
      <submit-button :is-loading="state.isSaving" class="bg-green-500">Save</submit-button>
    </template>
  </form-layout>
</template>

<script>
import { computed, reactive } from 'vue'
import http from '@/services/http.js'
import notification from '@/services/notifications.js'
import FormLayout from '@/components/form/Layout.vue'
import SubmitButton from '@/components/form/SubmitButton.vue'
import Errors from '@/components/form/Errors.vue'

export default {
  name: 'machines-alerts-update-form',

  components: {
    FormLayout,
    SubmitButton,
    Errors,
  },

  props: {
    machineId: {
      type: Number,
      required: true
    },
    hideAlerts: {
      type: Array,
      default: () => ([])
    },
  },

  setup(props) {
    const state = reactive({
      form: {},
      alerts: [],
      isIntialLoading: false,
      isSaving: false,
      validationErrors: [],
    })

    const get = async () => {
      state.isInitialLoading = true

      try {
        const { data } = await http.get(`machine/${props.machineId}/alerts`)

        data.forEach((item) => {
          state.form[item.alert_id] = item.variables
        })

        state.alerts = data.map((alert) => {
          const parts = alert.template.split(' ').map((part) => {
            if (part.includes('{{') && part.includes('}}')) {
              return {
                type: 'input',
                value: part.replace('{{', '').replace('}}', ''),
              }
            }

            return {
              type: 'string',
              value: part,
            }
          })
          return {
            name: alert.name,
            id: alert.alert_id,
            parts,
          }
        })
      } catch (e) {
        if (e.response) {
          state.hasAccess = false
        } else {
          state.validationErrors.push('Something went wrong. Please, try again.')
          state.hasError = true
        }
      }

      state.isInitialLoading = false
    }

    const update = async () => {
      state.isSaving = true

      try {
        const form = []

        for (let key in state.form) {
          form.push({
            alert_id: key,
            variables: state.form[key]
          })
        }

        await http.post(`machine/${props.machineId}/alerts`, form)
        notification.success('Success', `Machine Alerts have been updated`)
      } catch (e) {
        if (e.response) {
          state.validationErrors.push(e.response.data.Message)
          state.hasError = true
        } else {
          state.validationErrors.push('Something went wrong. Please, try again.')
          state.hasError = true
        }
      }

      state.isSaving = false
    }

    const alerts = computed(() => {
      return state.alerts.filter(alert => !props.hideAlerts.includes(alert.id))
    })

    get()

    return {
      state,
      update,
      alerts,
    }
  }
}
</script>
