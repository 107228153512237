<template>
  <form-layout
    :is-loading="false"
    title="Scheduled Reset Disclaimer"
    icon="task"
    @submit="accept"
  >
    <textarea readonly class="w-full" rows="15">Disclaimer and Release of Liability

Please read carefully and continue only if you understand and accepts the risks to life and equipment that may occur when enabling this feature.

I hereby understand and accept that remotely resetting the machinery manually or automatically may result in damage to equipment and will put you and your personnel at the machine at risk of injury or death due this feature.

I full accept and agree to the liability disclaimer below.

Liability Disclaimer
THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR AVAILABLE THROUGH THE SITE MAY INCLUDE INACCURACIES OR TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE INFORMATION HEREIN. PLCBRIDGE.COM, LLC AND/OR ITS SUPPLIERS MAY MAKE IMPROVEMENTS AND/OR CHANGES IN THE SITE AT ANY TIME.
 
PLCBRIDGE.COM, LLC AND/OR ITS SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, AND ACCURACY OF THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS CONTAINED ON THE SITE FOR ANY PURPOSE. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ALL SUCH INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS ARE PROVIDED "AS IS" WITHOUT WARRANTY OR CONDITION OF ANY KIND. PLCBRIDGE.COM, LLC AND/OR ITS SUPPLIERS HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS WITH REGARD TO THIS INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS, INCLUDING ALL IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT.
 
TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL PLCBRIDGE.COM, LLC AND/OR ITS SUPPLIERS BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF USE, DATA OR PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OR PERFORMANCE OF THE SITE, WITH THE DELAY OR INABILITY TO USE THE SITE OR RELATED SERVICES, THE PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS OBTAINED THROUGH THE SITE, OR OTHERWISE ARISING OUT OF THE USE OF THE SITE, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF PLCBRIDGE.COM, LLC OR ANY OF ITS SUPPLIERS HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE SITE, OR WITH ANY OF THESE TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SITE.
 
Termination/Access Restriction
PLCBRIDGE.COM, LLC reserves the right, in its sole discretion, to terminate your access to the Site and the related services or any portion thereof at any time, without notice. To the maximum extent permitted by law, this agreement is governed by the laws of the State of Texas and you hereby consent to the exclusive jurisdiction and venue of courts in Texas in all disputes arising out of or relating to the use of the Site. Use of the Site is unauthorized in any jurisdiction that does not give effect to all provisions of these Terms, including, without limitation, this section.
 
You agree that no joint venture, partnership, employment, or agency relationship exists between you and PLCBRIDGE.COM, LLC as a result of this agreement or use of the Site. PLCBRIDGE.COM, LLC performance of this agreement is subject to existing laws and legal process, and nothing contained in this agreement is in derogation of PLCBRIDGE.COM, LLC right to comply with governmental, court and law enforcement requests or requirements relating to your use of the Site or information provided to or gathered by PLCBRIDGE.COM, LLC with respect to such use. If any part of this agreement is determined to be invalid or unenforceable pursuant to applicable law including, but not limited to, the warranty disclaimers and liability limitations set forth above, then the invalid or unenforceable provision will be deemed superseded by a valid, enforceable provision that most closely matches the intent of the original provision and the remainder of the agreement shall continue in effect.
 
Unless otherwise specified herein, this agreement constitutes the entire agreement between the user and PLCBRIDGE.COM, LLC with respect to the Site and it supersedes all prior or contemporaneous communications and proposals, whether electronic, oral or written, between the user and PLCBRIDGE.COM, LLC with respect to the Site. A printed version of this agreement and of any notice given in electronic form shall be admissible in judicial or administrative proceedings based upon or relating to this agreement to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form. It is the express wish to the parties that this agreement and all related documents be written in English.
 
Changes to Terms
PLCBRIDGE.COM, LLC reserves the right, in its sole discretion, to change the Terms under which myvends.com is offered. The most current version of the Terms will supersede all previous versions. PLCBRIDGE.COM, LLC encourages you to periodically review the Terms to stay informed of our updates.</textarea>

    <template #actions>
      <div v-if="accepted || state.justAccepted">
        Accepted
      </div>
      <submit-button
        v-else
        :is-loading="state.isSaving"
        class="bg-green-500"
      >
        Save
      </submit-button>
    </template>
  </form-layout>
</template>

<script>
import { reactive } from 'vue'
import http from '@/services/http.js'
import notification from '@/services/notifications.js'
import FormLayout from '@/components/form/Layout.vue'
import SubmitButton from '@/components/form/SubmitButton.vue'

export default {
  name: 'MachinesScheduledResetDisclaimer',

  components: {
    FormLayout,
    SubmitButton,
  },

  props: ['machineId', 'accepted'],

  setup(props) {
    const state = reactive({
      isInitialLoading: false,
      isSaving: false,
      justAccepted: false,
    })

    const accept = async () => {
      state.isSaving = true
      try {
        await http.post(`machine/${props.machineId}/accept-scheduled-reset-disclaimer`)
        notification.success('Success', 'Disclaimer Accepted')
        state.justAccepted = true
      } catch (e) {
        notification.error('Error', e.response?.data?.Message || 'Request failed')
      }
      state.isSaving = false
    }

    return {
      state,
      accept,
    }
  }
}
</script>
