<template>
  <layout>
    <template #secondarySidebar>
      <settings-nav />
    </template>

    <alert-response-warning v-if="! state.hasAccess" :error="state.accessError" />

    <div v-else >
      <div class="flex flex-row justify-between items-center border-b pb-2">
        <h2 class="font-bold text-lg sm:text-2xl">{{ state.form.name }}</h2>

        <router-link :to="{ name: 'SettingsMachinesIndex'}" class="group">
          <div class="flex items-center">
            <svg class="w-4 h-4 text-gray-400 group-hover:text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"></path></svg>
            <p class="text-sm text-gray-500 group-hover:text-gray-900">Back</p>
          </div>
        </router-link>
      </div>

      <div class="space-y-6 xl:space-y-8 mt-6 xl:mt-8">
        <update-machine-form
          :is-loading="state.isInitialLoading"
          :form="state.form"
          :machine-id="parseInt(machineId)"
          :permissions="state.permissions"
          :register-mapping-options="state.registerMappingOptions"
          :timezone-options="state.timezoneOptions"
          @saved="savedForm"
        />

        <update-alerts-form :machine-id="machineId" :hideAlerts="hideAlerts" />

        <update-prices-form :machine-id="machineId" />

        <cameras-list v-if="state.isAdmin" :machine-id="machineId" />

        <scheduled-reset-disclaimer id="disclaimer" :machine-id="machineId" :accepted="state.dislaimerAccepted" />
      </div>
    </div>

  </layout>
</template>

<script>
import { computed, reactive } from 'vue'
import { useRoute } from 'vue-router'
import http from '@/services/http.js'
import Layout from '@/layouts/Default.vue'
import AlertResponseWarning from '@/components/alerts/ResponseWarning.vue'
import UpdatePricesForm from '@/components/machines/UpdatePricesForm.vue'
import UpdateMachineForm from '@/components/machines/UpdateMachineForm.vue'
import UpdateAlertsForm from '@/components/machines/UpdateAlertsForm.vue'
import CamerasList from '@/components/machines/CamerasList.vue'
import ScheduledResetDisclaimer from '@/components/machines/ScheduledResetDisclaimer.vue'
import SettingsNav from '@/components/settings/Nav'

export default {
  name: 'settings-machines-edit',

  components: {
    Layout,
    AlertResponseWarning,
    UpdatePricesForm,
    UpdateMachineForm,
    UpdateAlertsForm,
    CamerasList,
    ScheduledResetDisclaimer,
    SettingsNav
  },

  setup() {
    const route = useRoute()
    const machineId = route.params.id
    const state = reactive({
      hasAccess: true,
      accessError: null,
      isAdmin: false,
      form: {},
      updatedForm: {},
      registerMappingOptions: [],
      timezoneOptions: [],
      permissions: {
        readonly_fields: [],
      },
      isInitialLoading: false,
      dislaimerAccepted: false,
    })

    const getMachine = async () => {
      state.isInitialLoading = true

      try {
        const { data } = await http.get(`machine/${machineId}`)

        state.form = data.model
        state.updatedForm = data.model
        state.permissions = data.permissions
        state.registerMappingOptions = data.register_mapping_options
        state.timezoneOptions = data.timezone_options
        state.dislaimerAccepted = data.scheduled_reset_disclaimer_accepted
      } catch (e) {
        state.hasAccess = false
        state.accessError = e
      }

      state.isInitialLoading = false
    }

    const getUserAndSetIsAdmin = async () => {
      const { data } = await http.get('user/profile')

      state.isAdmin = data.is_administrator
    }

    const savedForm = (form) => {
      state.updatedForm = {...form}
    }

    const hideAlerts = computed(() => {
      return state.updatedForm.bag_counts_enabled ? [] : ['low-bags']
    })

    getMachine()
    getUserAndSetIsAdmin()

    return {
      state,
      machineId,
      savedForm,
      hideAlerts,
    }
  }
}
</script>
