<template>
  <form-layout
    :is-loading="state.isInitialLoading"
    title="Product Prices"
    icon="monetization_on"
    @submit="update"
  >
    <errors :errors="state.validationErrors" />

    <div class="grid grid-cols-1 md:grid-cols-4 gap-y-2 md:gap-x-4 xl:gap-x-8">
      <div class="grid-span-1 md:col-span-4 flex space-x-2">
        <number v-model.number="state.form.product1.price" :min="0" :step="0.05" label="Price 1" class="w-1/2 md:w-1/4" />
        <select-box v-model="state.form.product1.product_type" label="Price 1 Type" class="w-1/2 md:w-1/4">
          <option :value="null">None</option>
          <option v-for="option in price1Options" :value="option.key" :key="option.key">{{ option.label }}</option>
        </select-box>
      </div>

      <div class="grid-span-2 md:col-span-4 flex space-x-2">
        <number v-model.number="state.form.product2.price" :min="0" :step="0.05" label="Price 2" class="w-1/2 md:w-1/4" />
        <select-box v-model="state.form.product2.product_type" label="Price 2 Type" class="w-1/2 md:w-1/4">
          <option :value="null">None</option>
          <option v-for="option in price2Options" :value="option.key" :key="option.key">{{ option.label }}</option>
        </select-box>
      </div>

      <div class="grid-span-2 md:col-span-4 flex space-x-2">
        <number v-model.number="state.form.product3.price" :min="0" :step="0.05" label="Price 3" class="w-1/2 md:w-1/4" />
        <select-box v-model="state.form.product3.product_type" label="Price 3 Type" class="w-1/2 md:w-1/4">
          <option :value="null">None</option>
          <option v-for="option in price3Options" :value="option.key" :key="option.key">{{ option.label }}</option>
        </select-box>
      </div>

      <number v-model.number="state.form.gal1" :min="0" :step="0.05" label="1 Gallon" />
      <number v-model.number="state.form.gal3" :min="0" :step="0.05" label="3 Gallon" />
      <number v-model.number="state.form.gal5" :min="0" :step="0.05" label="5 Gallon" />
      <number v-model.number="state.form.rinse" :min="0" :step="0.05" label="Rinse" />
    </div>

    <template #actions>
      <submit-button
        :is-loading="state.isSaving"
        class="bg-green-500"
      >
        Save
      </submit-button>
    </template>
  </form-layout>
</template>

<script>
import { computed, reactive } from 'vue'
import http from '@/services/http.js'
import { sneakCaseToReadable } from '@/helpers/utils.js'
import notification from '@/services/notifications.js'
import FormLayout from '@/components/form/Layout.vue'
import SubmitButton from '@/components/form/SubmitButton.vue'
import Errors from '@/components/form/Errors.vue'
import Number from '@/components/form/Number'
import SelectBox from '@/components/form/Select'

export default {
  name: 'MachinesPricesUpdateForm',

  components: {
    SelectBox,
    Number,
    FormLayout,
    SubmitButton,
    Errors,
  },

  props: ['machineId'],

  setup(props) {
    const state = reactive({
      form: {
        product1: {},
        product2: {},
        product3: {},
      },
      isInitialLoading: false,
      isSaving: false,
      validationErrors: [],
    })

    const priceOptions = [
      { key: 'bag', label: 'Bag' },
      { key: 'small_bag', label: 'Small Bag' },
      { key: 'big_bag', label: 'Big Bag' },
      { key: 'two_for_one_bag', label: 'Two for one Bag' },
      { key: 'bulk', label: 'Bulk' },
    ]

    // const iceOptions = priceOptions.map((option) => option.key)

    const get = async () => {
      state.isInitialLoading = true

      try {
        const { data } = await http.get(`machine/${props.machineId}/prices`)

        state.form = {
          gal1: data.gal1 / 100,
          gal3: data.gal3 / 100,
          gal5: data.gal5 / 100,
          rinse: data.rinse / 100,
          product1: { product_type: data.product1.product_type, price: data.product1.price / 100 },
          product2: { product_type: data.product2.product_type, price: data.product2.price / 100 },
          product3: { product_type: data.product3.product_type, price: data.product3.price / 100 },
        }
      } catch (e) {
        if (e.response) {
          state.hasAccess = false
        } else {
          state.validationErrors.push('Something went wrong. Please, try again.')
          state.hasError = true
        }
      }

      state.isInitialLoading = false
    }

    const update = async () => {
      state.isSaving = true

      try {
        await http.post(`machine/${props.machineId}/prices`, {
          gal1: state.form.gal1 * 100,
          gal3: state.form.gal3 * 100,
          gal5: state.form.gal5 * 100,
          rinse: state.form.rinse * 100,
          product1: { product_type: state.form.product1.product_type, price: state.form.product1.price * 100 },
          product2: { product_type: state.form.product2.product_type, price: state.form.product2.price * 100 },
          product3: { product_type: state.form.product3.product_type, price: state.form.product3.price * 100 },
        })

        notification.success('Success', `Machine Prices have been updated`)
      } catch (e) {
        if (e.response) {
          state.validationErrors.push(e.response.data.Message)
          state.hasError = true
        } else {
          state.validationErrors.push('Something went wrong. Please, try again.')
          state.hasError = true
        }
      }

      state.isSaving = false
    }

    const price1Options = computed(() => {
      return priceOptions.filter((option) => option.key !== state.form.product2.product_type && option.key !== state.form.product3.product_type)
    })

    const price2Options = computed(() => {
      return priceOptions.filter((option) => option.key !== state.form.product1.product_type && option.key !== state.form.product3.product_type)
    })

    const price3Options = computed(() => {
      return priceOptions.filter((option) => option.key !== state.form.product1.product_type && option.key !== state.form.product2.product_type)
    })

    get()

    return {
      state,
      update,
      sneakCaseToReadable,
      price1Options,
      price2Options,
      price3Options,
    }
  }
}
</script>
