<template>
  <form-layout
    @submit="update"
    title="Machine"
    icon="info"
    :is-loading="isLoading"
  >
    <errors :errors="state.errors" />

    <div class="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4 xl:space-x-6">
      <div class="w-full md:w-1/2 flex flex-col space-y-2">
        <text-input label="Location Id" v-model="state.form.location_id" :required="true" :disabled="cannotEditField('location_id')" />
        <text-input label="Mac Address" v-model="state.form.mac" :required="true" :disabled="cannotEditField('mac')" />
        <text-input label="Name" v-model="state.form.name" :required="true" :disabled="cannotEditField('name')" />

        <select-box label="Timezone" v-model="state.form.timezone" :disabled="cannotEditField('timezone')">
          <option :value="null">Select Timezone</option>
          <option v-for="option in timezoneOptions" :key="option" :value="option">
            {{ option }}
          </option>
        </select-box>

        <select-box label="Machine Model" v-model="state.form.model" :disabled="cannotEditField('model')">
          <option v-for="option in machineModels" :key="option" :value="option">
            {{ option }}
          </option>
        </select-box>
      </div>

      <div class="w-full md:w-1/2 flex flex-col space-y-2">
        <text-input label="Address" v-model="state.form.address_address" :disabled="cannotEditField('address_address')" />
        <text-input label="City" v-model="state.form.address_city" :disabled="cannotEditField('address_city')" />
        <text-input label="State" v-model="state.form.address_state" :disabled="cannotEditField('address_state')" />
        <text-input label="Zip" v-model="state.form.address_zip" :disabled="cannotEditField('address_zip')" />
      </div>
    </div>

    <div class="w-full mt-4 xl:mt-6">
      <div class="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4 bg-gray-50 rounded-md p-4 xl:p-6">
        <div class="w-full md:w-2/5">
          <time-picker label="Business Hours Start" v-model="state.form.business_hours_start" />
        </div>
        <div class="w-full md:w-2/5">
          <time-picker label="Business Hours End" v-model="state.form.business_hours_end" />
        </div>
        <div class="w-full md:w-1/5 flex items-center text-gray-700 text-xs uppercase font-bold md:pt-6">
          {{ state.form.timezone }}
        </div>
      </div>
    </div>

    <div class="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4 xl:space-x-6 mt-4 xl:mt-6">
      <div class="w-full md:w-1/2">
        <autocomplete-text-input
          label="Owner"
          v-model="state.form.owner"
          :required="false"
          :disabled="cannotEditField('owner')"
          :suggestions="state.users"
        />
      </div>
      <div class="w-full md:w-1/2 flex items-center">
        <label class="mt-4 md:mt-0 text-sm font-semibold text-blumine">
          <input type="checkbox" v-model="state.form.bag_counts_enabled" :value="true" class="rounded text-blumine">
          <span class="ml-2">Bag Counts Enabled</span>
        </label>
      </div>
    </div>

    <template #actions>
      <submit-button :is-loading="state.isSaving">Save</submit-button>
    </template>
  </form-layout>
</template>

<script>
import { reactive, watch } from 'vue'
import http from '@/services/http.js'
import notification from '@/services/notifications.js'
import date from '@/helpers/date.js'
import { sneakCaseToReadable } from '@/helpers/utils'
import { machineModels } from '@/helpers/machine'
import FormLayout from '@/components/form/Layout.vue'
import TextInput from '@/components/form/Text.vue'
import AutocompleteTextInput from '@/components/form/AutocompleteText.vue'
import TimePicker from '@/components/form/TimePicker.vue'
import SelectBox from '@/components/form/Select.vue'
import Errors from '@/components/form/Errors.vue'
import SubmitButton from '@/components/form/SubmitButton.vue'

export default {
  name: 'machines-update-machine-form',

  components: {
    TextInput,
    AutocompleteTextInput,
    SubmitButton,
    Errors,
    SelectBox,
    TimePicker,
    FormLayout,
  },

  props: {
    form: {
      type: Object,
      default: () => ({}),
    },
    machineId: {
      type: Number,
      required: true,
    },
    permissions: {
      type: Object,
      default: () => ({
        readonly_fields: [],
      }),
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    timezoneOptions: {
      type: Array,
      required: true,
    },
    registerMappingOptions: {
      type: Array,
      required: true,
    }
  },

  emits: ['saved'],

  setup(props, { emit }) {
    const state = reactive({
      form: props.form,
      errors: [],
      permissions: [],
      isSaving: false,
      users: [],
    })

    watch(
      () => props.form,
      (form) => state.form = {
        ...form,
        business_hours_start: date.timeFormat(form.business_hours_start).fromApi(),
        business_hours_end: date.timeFormat(form.business_hours_end).fromApi(),
      }
    )

    const cannotEditField = (field) => props.permissions.readonly_fields.includes(field)

    const update = async () => {
      state.isSaving = true
      state.errors = []

      try {
        const result = await http.patch(`machine/${props.machineId}`, {
          bag_counts_enabled: false,
          ...state.form,
          business_hours_start: date.timeFormat(state.form.business_hours_start).forApi(),
          business_hours_end: date.timeFormat(state.form.business_hours_end).forApi(),
        })
        state.form.mac = result.data.mac
        notification.success('Success', `Machine with id ${props.machineId} has been updated`)
        emit('saved', state.form)
      } catch (e) {
        if (e.response) {
          state.errors.push(e.response.data.Message)
        } else {
          state.errors.push('Something went wrong. Please, try again.')
        }
      }

      state.isSaving = false
    }

    const getUsers = async () => {
      try {
        const { data } = await http.get('users')
        data.sort((a, b) => a.email.localeCompare(b.email))
        state.users = data.map(user => user.email)
      } catch (e) {
        notification.error('Error loading Users for Owner Autocomplete')
      }
    }

    watch(
      () => props.permissions,
      () => {
        if (props.permissions && !cannotEditField('owner')) {
          getUsers()
        }
      }
    )

    return {
      update,
      sneakCaseToReadable,
      state,
      cannotEditField,
      machineModels,
    }
  }
}
</script>
