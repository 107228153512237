<template>
  <form-layout
    title="Cameras"
    icon="camera_indoor"
  >
    <v-table :is-loading="state.isLoading">
      <template #header>
        <v-table-header>Name</v-table-header>
        <v-table-header><span class="sr-only">Actions</span></v-table-header>
      </template>

      <v-table-row v-for="(camera, index) in cameras" :key="index" class="hover:bg-gray-50">
        <v-table-col>{{ camera.camera_id }} - {{ camera.url }}</v-table-col>
        <v-table-col class="text-right space-x-2 flex justify-end items-center">
          <router-link :to="{ name: 'SettingsMachinesCamerasEdit', params: { machineId, id: camera.camera_id } }" class="w-8 h-8 flex items-center text-blumine hover:text-big-stone transition ease-out duration-150">
            <span class="sr-only">edit</span>
            <span class="material-icons-outlined">create</span>
          </router-link>
          <button @click="remove(camera)" class="w-8 h-8 flex items-center text-blumine hover:text-red-600 transition ease-out duration-150 focus:outline-none">
            <span class="sr-only">delete</span>
            <span class="material-icons-outlined">delete</span>
          </button>
        </v-table-col>
      </v-table-row>

      <v-table-row v-if="cameras.length === 0">
        <v-table-col>
          <div class="flex items-center"><span class="material-icons mr-1">videocam_off</span>No Cameras</div>
        </v-table-col>
      </v-table-row>
    </v-table>

    <template #add>
      <router-link :to="{ name: 'SettingsMachinesCamerasCreate', params: { machineId } }">
        <add-button-inner title="Add" icon="add_circle" />
      </router-link>
    </template>
  </form-layout>
</template>

<script>
import { reactive, ref } from 'vue'
import http from '@/services/http'
import notification from '@/services/notifications.js'
import FormLayout from '@/components/form/Layout'
import VTable from '@/components/Table.vue'
import VTableHeader from '@/components/TableHeader.vue'
import VTableRow from '@/components/TableRow.vue'
import VTableCol from '@/components/TableCol.vue'
import AddButtonInner from '@/components/buttons/AddButtonInner.vue'

export default {
  components: {
    FormLayout,
    VTable,
    VTableHeader,
    VTableRow,
    VTableCol,
    AddButtonInner,
  },

  props: ['machineId'],

  setup(props) {
    const cameras = ref([])
    const state = reactive({
      isLoading: false,
    })

    const getCameras = async () => {
      state.isLoading = true

      const { data } = await http.get(`/machine/${props.machineId}/cameraedit`)

      cameras.value = data

      state.isLoading = false
    }

    const remove = async (camera) => {
      if (window.confirm(`Do you really want delete camera ${camera.camera_id}?`)) {
        try {
          await http.delete(`/machine/${props.machineId}/camera/${encodeURIComponent(camera.camera_id)}`)
          const index = cameras.value.findIndex((c) => c.camera_id === camera.camera_id)
          cameras.value.splice(index, 1)
          notification.success('Success', `Camera with ID <b>${camera.camera_id}</b> has been deleted`)
        } catch (e) {
          notification.error('Error', 'Something went wrong. Please, try again.')
        }
      }
    }

    getCameras()

    return {
      state,
      cameras,
      remove,
    }
  }
}
</script>
